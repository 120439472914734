// contexts/CategoryContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { useCart } from "./CartContext";
import { useRouter } from "next/router";
import { megaMenuItems } from "~/constants/mega-menu";

const OrderConfirmContext = createContext();

export const OrderConfirmContextProvider = ({ children }) => {
  const [orderData, setOrderData] = useState(null);
  const { abortController } = useCart();
  const router = useRouter();
  const { query } = router;
  // console.log("Confirmation - query", query);
  const { result, payment_intent, paypalOrderId, paymentIntentId } = query;
  // let foundResult = typeof window !== 'undefined' && localStorage.getItem("result");
  //alert("called")
  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     // Access localStorage here
  //     foundResult = localStorage.getItem("result");
  // }
  // }, []);

  const fetchData = async () => {
    // let catData;

    try {
      if (paypalOrderId) {
        const paypalOrderResponse = await fetch(
          `${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/commerce/paypal/retrieve-order`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              orderId: paypalOrderId,
            }),
          }
        );

        const paypalOrderData = await paypalOrderResponse?.json();

        //console.log('paypalOrderDataCONFIRMATION', paypalOrderData);

        const orderIdFromPaypalMetadata = paypalOrderData?.purchase_units?.find(
          (item) => item.custom_id
        );

        //console.log('orderIdFromPaypalMetadata', orderIdFromPaypalMetadata);

        const retrievedWooCommerceOrderResponse = await fetch(
          `${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/commerce/orders/get-order`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              orderId: orderIdFromPaypalMetadata?.custom_id,
            }),
          }
        );

        const retrievedWooCommerceOrderData =
          await retrievedWooCommerceOrderResponse?.json();

        // console.log("retrievedWOO", retrievedWooCommerceOrderData);
        setOrderData({
          paypalOrderData,
          wooCommerceOrderData: retrievedWooCommerceOrderData,
        });

        // return {
        //   props: {
        //     paypalOrderData,
        //     wooCommerceOrderData: retrievedWooCommerceOrderData,
        //   },
        // };
      }
      // console.log("result", result);
      const retrievedStripePaymentIntentResponse = await fetch(
        `${
          process.env.NEXT_PUBLIC_FRONTEND_URL
        }/api/commerce/stripe/retrieve-payment-intent?id=${
          result || payment_intent || paymentIntentId
        }`,
        {
          method: "POST",
        }
      );

      const retrievedStripePaymentIntentData =
        await retrievedStripePaymentIntentResponse.json();

      // console.log('retrievedStripePaymentIntentData', retrievedStripePaymentIntentData);

      const orderIdFromStripeMetadata =
        retrievedStripePaymentIntentData?.metadata?.orderId;

      // if (retrievedStripePaymentIntentData.error) {
      //   //console.log('Invalid stripe session, redirecting to homepage');
      //   return {
      //     redirect: {
      //       destination: "/",
      //       permanent: false,
      //     },
      //   };
      // }

      // gets the order from WooCommerce to get the order number and so on

      //console.log('orderIdFromStripeMetadata', orderIdFromStripeMetadata);

      const retrievedWooCommerceOrderResponse = await fetch(
        `${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/commerce/orders/get-order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orderId: orderIdFromStripeMetadata,
          }),
        }
      );
      const retrievedWooCommerceOrderData =
        await retrievedWooCommerceOrderResponse.json();

      // return {
      //   props: {
      //     stripeCheckoutData: retrievedStripePaymentIntentData,
      //     wooCommerceOrderData: retrievedWooCommerceOrderData,
      //   },

      // const coockedFoodResponse = await fetch(
      //   `${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/category/get-category-data`,
      //   {
      //     method: "POST",
      //     body: JSON.stringify({ categoryHandle: item?.slug }),
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     signal: abortController?.signal,
      //   }
      // );

      //const data = await Promise.all(catData);
      //setOrderData(retrievedStripePaymentIntentData);
      // if (retrievedStripePaymentIntentData?.status === "succeeded") {
        setOrderData({
          stripeCheckoutData: retrievedStripePaymentIntentData,
          wooCommerceOrderData: retrievedWooCommerceOrderData,
        });
      // }
    } catch (error) {
      // console.log("get Order confirmation Error", error);
    }
    //return null;
  };

  useEffect(() => {
    if (orderData === null) {
      fetchData();
    }
  }, [orderData === null]);

  return (
    <OrderConfirmContext.Provider value={orderData}>
      {children}
    </OrderConfirmContext.Provider>
  );
};

export const useOrderConfirmContext = () => {
  return useContext(OrderConfirmContext);
};
