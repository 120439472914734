import { TUCKERTUB_AUTH_TOKEN, WOOCOMMERCE_TUCKERTUB_ORDER } from "~/constants";
import Cookies from "js-cookie";


interface UpdateWooOrderProps {
  abortController: AbortController | null;
  userEmail: string;
  setOrder: (payload: any) => void;
  selectedShippingMethod: any;
}

export const updateWooOrder = async (orderData: UpdateWooOrderProps) => {
  const { abortController, userEmail, setOrder, selectedShippingMethod } =
    orderData;
  //console.log("selectedShippingMethod", selectedShippingMethod);

  try {
    const foundOrder = localStorage.getItem(WOOCOMMERCE_TUCKERTUB_ORDER);
    const parsedOrder = JSON.parse(foundOrder ?? "{}");

    //console.log("updateWooOrderPARSEDORDER", parsedOrder);

    const shippingId =
      parsedOrder?.shipping_lines.length > 0
        ? { id: parsedOrder?.shipping_lines[0].id }
        : {};

    const updatedShippingLine = {
      ...shippingId,
      method_id: selectedShippingMethod.id,
      method_title: selectedShippingMethod.title,
      total: selectedShippingMethod?.total?.value?.toString() || '0',
    };

    const updatedOrderInput = {
      shipping: {
        ...parsedOrder.shipping,
      },
      billing: {
        ...parsedOrder.billing,
      },
      shipping_lines: [updatedShippingLine],
    };

   // console.log("ORDERIDDDDDDDDDDDDDDDDD_UpdateWooOrder", parsedOrder.id);
    if (parsedOrder.id) {
      const accessToken = Cookies.get(TUCKERTUB_AUTH_TOKEN)
      const isGuestCheckout = !accessToken 

      const response = await fetch(
        `${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/commerce/orders/update-order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          signal: abortController?.signal,
          body: JSON.stringify({
            orderId: parsedOrder.id,
            orderInput: updatedOrderInput,
            orderEmail: userEmail ?? parsedOrder.email,
            isGuestCheckout,
            accessToken:accessToken || '' 
          }),
        }
      );

      if( response.status === 401 ){
        Cookies.remove(TUCKERTUB_AUTH_TOKEN);
        window.location.replace('/account');
      }

      if (!response.ok) {
        throw new Error("Error updating order");
      }

      const data = await response.json();

      //console.log("UpdatedORDERRRRRRRR", data);

      const updatedOrder = {
        id: data.id,
        shipping: {
          ...data.shipping
        },
        billing: {
          ...data.billing
        },
        line_items: data.line_items,
        shipping_lines: data.shipping_lines,
        shippingOptions: parsedOrder.shippingOptions,
        total: data.total,
        email: userEmail
      }
      setOrder(updatedOrder);

      localStorage.setItem(
        WOOCOMMERCE_TUCKERTUB_ORDER,
        JSON.stringify(updatedOrder)
      );
      return updatedOrder
    } else {
      const updatedOrder = {
        // id: data.id,
        shipping: {
          ...updatedOrderInput.shipping,
        },
        billing: {
          ...updatedOrderInput.billing,
        },
        line_items: parsedOrder.line_items,
        shipping_lines: updatedOrderInput.shipping_lines,
        shippingOptions: parsedOrder.shippingOptions,
        total: parsedOrder.total,
        email: userEmail,
      };

      setOrder(updatedOrder);

      localStorage.setItem(
        WOOCOMMERCE_TUCKERTUB_ORDER,
        JSON.stringify(updatedOrder)
      );

      return updatedOrder;
    }
  } catch (error) {
    // console.log("useUpdateWooOrder error", error);
  }
};
