import React, { createContext, useContext, useState, useEffect } from "react";
import { useCart } from "./CartContext";
import { useRouter } from 'next/router';

const CategoryContext = createContext();

export const CategoryContextProvider = ({ children }) => {
  const [categoryData, setCategoryData] = useState([]);
  const { abortController } = useCart();
  const router = useRouter();
  const { query } = router;
  const { categoryHandle, productHandle } = query;

  const fetchData = async () => {
    // console.log("Request Body categoryHandle:", categoryHandle);
    try {
      const catData = [];

      const slugResponse = await fetch(
        `${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/category/get-category-slug`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          signal: abortController?.signal,
        }
      );
      const slugData = await slugResponse.json();
      const maintModeResponse = await fetch(
        `${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/category/get-category-maint-mode`,
        {
          method: "POST",
          body: JSON.stringify({ slugRespData: slugData }),
          headers: {
            "Content-Type": "application/json",
          },
          signal: abortController?.signal,
        }
      );
      const maintModeData = await maintModeResponse.json();
      catData.push(maintModeData);

      const rawFoodResponse = await fetch(
        `${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/category/get-category-raw-food`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          signal: abortController?.signal,
        }
      );
      const rawFood = await rawFoodResponse.json();
      catData.push(rawFood);

      const treatsFoodResponse = await fetch(
        `${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/category/get-category-treats`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          signal: abortController?.signal,
        }
      );
      const treatsFood = await treatsFoodResponse.json();
      catData.push(treatsFood);

      const dryFoodResponse = await fetch(
        `${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/category/get-category-dry-food`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          signal: abortController?.signal,
        }
      );
      const dryFood = await dryFoodResponse.json();
      catData.push(dryFood);

      setCategoryData(catData);
    } catch (error) {
      // console.log("get category Error", error);
    }
  };

  useEffect(() => {
    if (categoryData.length === 0) {
      // fetchData();
    }
  }, [categoryData]);

  // useEffect(() => {
  //   const intervalId = setInterval(fetchData, 600000); // Fetch data every minute

  //   return () => clearInterval(intervalId);
  // }, []);

  const updateData = (newData) => {
    if (categoryData) {
      let newArr = [...categoryData];
      let categoryIndex = newArr?.findIndex(
        (category) => category?.slug === categoryHandle
      );
      if (categoryIndex !== -1) {
        // Find the index of the product in the products array of the category
        let productIndex = newArr[categoryIndex]?.products?.findIndex(
          (product) => product.slug === productHandle
        );
        if (productIndex !== -1) {
          // Update variations for the product
          //newArr[categoryIndex]?.products[productIndex]?.variations = "sfffsdf";
          newArr[categoryIndex].products[productIndex].variationsData = newData;
          //alert(newArr[categoryIndex]?.products[productIndex]?.variations)
          // console.log("Variations updated successfully:", newArr);
        } else {
          // console.log("Product not found in the category.");
        }
      } else {
        // console.log("Category not found.");
      }

      setCategoryData(newArr);
    }
  };


  return (
    <CategoryContext.Provider value={{ categoryData, updateData }}>
      {children}
    </CategoryContext.Provider>
  );
};

export const useCategoryContext = () => {
  return useContext(CategoryContext);
};
